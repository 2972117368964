import React from 'react'

import Layout from '../components/layout'
import StrategyOne from '../components/strategy/StrategyOne'


const Work = props => (
  <Layout lang="pt" title="Estratégia ESG" meta={{description: ''}} rootProps={{ className: 'page strategy' }}>
    <StrategyOne lang="pt" />
  </Layout>
)

export default Work
